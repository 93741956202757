<template>
	<div class="mhscodesFormHeader puiformheader">
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mhscodes-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mhscodes'
		};
	},
	computed: {
	}
}
</script>
